import React, { Component } from 'react';
import { connect } from 'react-redux'
import parse from 'html-react-parser';
// import { navigate } from 'gatsby';

import Layout from 'components/layout/internal';
import Link from 'shared/link';
import SEO from 'shared/seo'
import style from './search.module.scss';
import TextInput from 'shared/form/textinput/';

const SEARCH_URL = `${process.env.GATSBY_ZMS_API_URL}search/all/`;

class SearchPage extends Component {
	
	constructor(props) {
		super(props);
		const { location } = props;
		let terms = '';
		if (location.search && props.terms === '') {
			let split = location.search.substr(1).split('=');
			split.shift()
			terms = split.join('=');
			terms = decodeURIComponent(terms).replace(/\+/g, ' ');
		}
		else terms = props.terms;
		this.handleSearch = this.handleSearch.bind(this);
		this.handleCategory = this.handleCategory.bind(this);
		this.setPage = this.setPage.bind(this);
		this.timerID = null;
		this.state = {
			terms: terms,
			isSearchLoading: false,
			page: 1,
			pageSize: 20,
			count: 0,
			pages: 1,
			results: props.results,
			selected: null,
			waitingToSearch: false,
		};
	}doSearch(page = 1) {
		this.setState({
			page: page,
			isSearchLoading: true,
			waitingToSearch: false,
		});
		const terms = this.state.terms;
		if (terms !== '') {
			fetch(`${SEARCH_URL}?terms=${terms}&_page=${page}&_pageSize=${this.state.pageSize}`)
				.then((response) => {
					return response.json();
				})
				.then((json) => {
					this.props.setSearch(terms, json.data||[]);
					this.setState({
						isSearchLoading: false,
						results: json.data||[],
						page: json.page,
						pages: json.pages,
						count: json.total,
					});
					// navigate(`?terms=${terms}`, {
					// 	replace: true,
					// });
				})
				.catch((err) => {
					console.log(err);
				})
			;
		}
		else {
			this.setState({
				isSearchLoading: false,
				results: [],
				page: 1,
				pages: 1,
				count: 0,
			});
		}
	}

	handleSearch(evt) {
		this.setState({
			terms: evt.target.value,
			isSearchLoading: true,
			waitingToSearch: true,
		});
		if (this.timerID !== null)clearTimeout(this.timerID);
		this.timerID = setTimeout(function(){
			this.doSearch();
		}.bind(this), 1000);
	}

	componentWillUnmount() {
		if (this.timerID !== null)clearTimeout(this.timerID);
	}

	setPage(e) {
		this.doSearch(e.target.innerText);
	}

	createPages() {
		let pages = [];
		if (this.state.pages > 1) {
			for (let i=1; i<=this.state.pages; i++) {
				pages.push(<button style={{ display:'inline-block', margin:'0 10px', fontWeight:(i === this.state.page ? 'bold' : '')}} key={i} onClick={this.setPage}>{i}</button>);
			}
		}
		if (pages.length > 0)return (<div>Page: {pages}<br/><br/></div>);
		return (<div/>);
	}


	componentDidMount() {
		if (this.state.terms !== '') {
			this.doSearch();
		}
	}

	handleCategory(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		let selected = null;
		if (evt.target.href.substring(evt.target.href.indexOf('#')+1) !== 'all') {
			selected = evt.target.href.substring(evt.target.href.indexOf('#')+1);
		}
		this.setState({
			selected: selected,
		});
	}

	render() {
		console.log(this.state.results);
		return (
			<>
			<Layout>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12 medium-offset-2 medium-8 end">
							<h1 className={style.pageTitle}>Search Results</h1>
							<TextInput autoComplete={false} label="I Am Looking For" name="terms" value={this.state.terms} onChange={this.handleSearch} />
							<section className={[style.mainContent, "main-content"].join(' ')}>
								{ this.state.terms !== '' && this.state.count === 0 && !this.state.isSearchLoading && <div className={style.intro}>Sorry, we could not find anything with those search terms.</div> }
								{ this.state.isSearchLoading && !this.state.waitingToSearch && <div className={style.intro}>Please wait while loading results.</div> }
								{this.state.results.length > 0 && 
									<div className={style.resultsContainer}>
										{this.state.results.map(item => (
										<div className={style.searchItem} key={item.id}><Link className={style.link} href={item.uri}>
											<h4>{item.title}</h4>
											{item.description && parse(item.description)}
										</Link><br/><br/></div>
										))}
									</div>}
							</section>
						</div> 
					</div>
				</div>
			</Layout>
			<SEO title={'Site Search | Heritage Medical Associates'} />
			</>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		terms: state.terms,
		results: state.results,
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		setSearch: (terms, results) => dispatch({
			type: 'SET_SEARCH_RESULTS',
			terms: terms,
			results: results
		}),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);